body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.filepond-label-idle-override {
  font-weight: 400;
  color: #a3a3a3;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.filepond-label-idle-action-override {
  font-weight: 400;
  color: #175882;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.filepond-container-override > .filepond--drop-label {
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c2c2c2;
}

.override-ql-viewer {
  color: #333333 !important;
}

.override-ql-viewer > h1 {
  font-family: 'Lato', sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  letter-spacing: 0.15px !important;
  color: #333333 !important;
}

.override-ql-viewer > h1 > a {
  text-decoration-line: underline !important;
  color: #165581 !important;
}

.override-ql-viewer > h2 {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  color: #333333 !important;
}

.override-ql-viewer > h2 > a {
  text-decoration-line: underline !important;
  color: #165581 !important;
}

.override-ql-viewer > h3 {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  color: #333333 !important;
}

.override-ql-viewer > h3 > a {
  text-decoration-line: underline !important;
  color: #165581 !important;
}

.override-ql-viewer > h4 {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  color: #333333 !important;
}

.override-ql-viewer > h4 > a {
  text-decoration-line: underline !important;
  color: #165581 !important;
}

.override-ql-viewer > h5 {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  color: #333333 !important;
}

.override-ql-viewer > h5 > a {
  text-decoration-line: underline !important;
  color: #165581 !important;
}

.override-ql-viewer > p {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  color: #333333 !important;
}

.override-ql-viewer > p > a {
  text-decoration-line: underline !important;
  color: #165581 !important;
}

.override-ql-viewer > blockquote {
  border-left: 4px solid #ccc !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  padding-left: 16px !important;
  color: #333333 !important;
}

.override-ql-viewer > ol {
  color: #333333 !important;
}

.override-ql-viewer > ul {
  color: #333333 !important;
}
